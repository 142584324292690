.training-video {
  .MuiDialogContent-root {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
  }

  video {
    margin-bottom: 15px;
  }
}