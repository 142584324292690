.tabsContent {
  margin: 12px 20px 35px;
}

.tabsContainer {
  position: relative;
}

.buttonContainer {
  position: absolute;
  right: 20px;
  z-index: 11;
}
