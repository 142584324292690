@import '~apollo-react/fonts/font.css';
@import './common/styles/variable.scss';

body {
  margin: 0;
  background-color: $bodyColor;
  font-family: Proxima Nova, Nunito Sans, sans-serif;
  color: $neutral8;
}

ol {
  margin: 0;
  padding: 0;
}

.createProjectModal [class^='MuiDialogActions-root'] {
  display: none;
}
.addCurveModal [class^='MuiDialogActions-root'] {
  display: none;
}

.createProjectModal,
.addCurveModal {
  overflow-x: hidden !important;
}

.MuiAppBar-root {
  z-index: 1100 !important;
}

.dueDatePicker {
  z-index: 99999 !important;
}

.a-MuiAutocomplete-input {
  border: none !important;
}

.a-MuiAutocomplete-root {
  margin-top: 16px;
  margin-bottom: 8px;
}

.a-MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .a-MuiAutocomplete-input:first-child {
  border: none !important;
}

/* Can be removed once apollo has these in built */
.projectBreadcrumb {
  margin-bottom: 1.5rem !important;
  position: relative;
  left: -7px;
}

.projectBreadcrumb > button:first-child {
  display: none !important;
}

.projectBreadcrumb > div:first-of-type svg {
  display: none !important;
}

.sessionContent .hideHomeIcon .MuiIconButton-root {
  display: none;
}
.outputContent .hideHomeIcon .MuiIconButton-root {
  display: none;
}

.projectDetail div[class^='Breadcrumbs-item']:nth-child(2) svg {
  display: none;
}

.CurvesTable div[class^='Table-tableWrapper'] {
  height: 120px;
}

.fiveColumnLayout {
  & > div {
    & > div {
      width: 255px;
    }
  }
}

.sixColumnLayout {
  & > div {
    & > div {
      width: 20%;
      margin-right: 1%;
    }
    & > div:last-child {
      margin-right: 0% !important;
      width: 10% !important;
    }
  }
}

#line {
  .recharts-legend-wrapper {
    top: -5px !important;

    .recharts-default-legend {
      li {
        padding-right: 5px;
        span {
          position: relative;
          top: 1px;
        }
      }
    }
  }
  .recharts-cartesian-axis-tick-value {
    font-size: 12px;
  }
}

/* .sessionContent
  .sessionBreadCrumb
  div[class^='Breadcrumbs-item']:nth-child(2)
  svg {
  display: none !important;
} */

/* .sessionContent > div:first-of-type svg {
  display: none !important;
} */
/* div[class^='MuiExpansionPanel-root'] {
  border: none;
} */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

div[class^='MuiFormControl'] {
  label {
    color: $black !important;
  }
}

div[class^='Footer-root'] {
  height: 24px;
  padding: 12px;
}

.footerContainer {
  height: 24px !important;
  padding: 12px !important;
}

.footerContainer svg[alt^='www.iqvia.com'] {
  margin-left: 70px;
}

div[class^="MuiTypography-root"]{
  font-family: Arial, Helvetica, sans-serif !important;
}

.MuiCircularProgress-colorSecondary {
  color: $neutral4 !important;
}

.MuiCircularProgress-colorPrimary {
  color: $primary !important;
}

.MuiAccordionDetails-root {
  display: block !important;
}

div[class^="MuiAutocomplete-popper"]{
  z-index: 3001 !important;
}

//relative lower z-index is chosen based on default z-index of apollo modal which is 1300
div[class^='MuiAppBar-root'] {
  z-index: 1298 !important;
} 

//z-index of banner should be less than that of modal which is 1300
div[class^='MuiSnackbar-root'] {
  z-index: 1298 !important;
} 

.clickableTooltip {
  button {
    font-size: 15px;
    display: inline-flex;
    width: max-content;
    border-radius: 0px;
    span{
      padding-right: 5px;
    }
    &:hover {
      background-color: transparent !important;
    }
  }
}
