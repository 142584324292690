.projectNameCell {
  color: #0768fd;
  font-weight: bold;
}
.settings {
  margin-top: 6;
  margin-left: 10;
}

.autoCompleteInputRootPJ {
  // padding-left: 0.5rem;
  height:33px;
  .actionSectionPJ {
      top: calc(50% - 20px);
      button {
          background-color: transparent;
      }
  }
  .autoCompleteInputPJ {
      border: none;
      height: 14px;
  }
  .autoCompleteChipPJ {
      color: #fff;
      background-color: #0768fd;
  }
}

.autoCompletePoperPJ {
  .autoCompletePaperPJ {
      box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.08);

      li {
          position: relative;
          padding-left: 30px;
  
          &[data-focus="true"], &[aria-selected="true"], &[aria-disabled="true"] {
              background-color: transparent;
          }
          &:hover {
              color: #000000;
              background-color: rgba(35, 114, 253, 0.08);
          }
          &[aria-disabled="true"]:hover {
              background-color: transparent;
              
          }
          &[aria-disabled="true"] {
              opacity: 1;
          }
  
          svg {
              position: absolute;
              left: 5px;
              top: 8px;
          }
  
          .listItemLabel {
              color: #444444;
          }
      }
  }
}

.successBanner{
  top: 57px;
}

.tableWrapper {
    table thead tr:first-child > th {
        white-space: nowrap;
      }
}

.tenantTableWrapper {
    table thead tr:first-child > th:last-child > svg:last-child {
        display: none;
      }
}